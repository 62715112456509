.question-preview-popup {
    .ant-modal-content {
        padding: 40px 84px;
        .ant-modal-header, .ant-modal-body {
            padding-left: 0;
        }
        .modal-close-btn{
            padding: 0 50px;
        }
    }

    .question-wrap, .description-wrap {
        margin-top: 10px;
    }
    .description-wrap, .tags-wrap, .custom-fields-wrap  {
        margin-top: 2rem;   
    }
    .qp-heading {
        @extend .font-weight-semibold;
        color: rgba(33, 33, 33, 0.9);
        font-size: 1rem;
    }
    .question {
        @extend .font-weight-semibold;
        color: #000000;
        font-size: 1.5rem;
    }
    .description {
        @extend .font-weight-normal;
        font-size: 1.5rem;
        color: #000000;
    }
    .qp-tags {
        border: 1px solid rgba(234, 234, 234, 0.4);
        border-radius: 2px;
        padding: 10px 15px;
    }
    .cf-text {
        @extend .font-weight-semibold;
        font-size: 24px;
        color: #676767;
       & + .cf-text {
           margin-top: 2rem;
       }
    }
    .ant-modal-footer {
        border-top: none;
    }

}