.form-container {
  @extend .bg-white;

  .container-card {
    max-width: 824px;
    margin: 0 auto;
    padding-bottom: 7.063rem;

    h3 {
      @extend .font-weight-semibold,
      .mb-0;
      font-size: 1.5rem;
      color: #000000;
      // padding: 1.25rem 0;
      border-bottom: 1px solid #e9e9e9;
    }

    h5 {
      @extend .font-weight-medium;
      font-size: 1.25rem;
      color: #383838;
      padding: 1.563rem 0;
    }

    label {
      @extend .w-100;

      .anticon-info-circle {
        @extend .ml-auto;
      }
    }

    .form-submit-btn {
      background: $primary;
      border-radius: 2px;
      min-height: 48px;
      margin-top: 1.5rem;
    }

    .upload-logo-style {
      // width: 46px;
      // height: 46px;
      border-radius: 50%;
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }

    .dragger-img-box {
      @extend .d-flex,
      .justify-content-center,
      .align-items-center;

      min-height: 155px;
    }

    .organization {
      .upload-image-style {
        position: relative;
        width: 80px;
        height: 80px;
        margin: auto;
        border: 1px solid #e7e7e7;
        border-radius: 50%;
        overflow: hidden;

        img {
          position: absolute;
          max-width: 100%;
          max-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }
      }
    }

    .dragger-img-box {
      background: #ffffff;
      border: 2px dotted #e7e7e7;

      p {
        @extend .font-weight-medium;
        font-size: 1rem;
        color: #afafaf;
        margin-bottom: .5rem;
        margin-top: .5rem;
      }

      .img-upload-btn {
        @extend .font-weight-medium,
        .d-flex,
        .justify-content-center,
        .align-items-center,
        .mx-auto;
        background: #f1f1f1;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        width: 94px;
        height: 32px;
        font-size: 0.875rem;
      }
    }
  }
}

// organizations-list scss

.question_list_filter {
  padding-left: 0.188rem;
  padding-top: 0.688rem;

  .ant-card-head-title {
    padding-bottom: 0.5rem;
  }

  .lf-search-bar {
    @extend .w-100;
    margin-bottom: 1rem;
  }

  .apply-filter-btn {
    @extend .w-100;
    margin-bottom: 1rem;
  }

  .list-actions {
    @media screen and (min-width: 800px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 799px) {
      button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .list-heading {
    @extend .font-weight-bold;
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 1rem;
  }
}

.virtual-list {
  .vl-heading {
    @extend .d-flex,
    .align-items-center;
    background: #fafafa;
    min-height: 69px;

    .ant-col {
      padding: 0 1.25rem;

      h3 {
        @extend .mb-0,
        .font-weight-semibold;
        font-size: 1rem;
        color: #212121;
      }
    }
  }

  .vl-items {
    padding-top: 1.25rem;
    border-top: 1px solid #e8e8e8;

    // border-bottom: 1px solid #E8E8E8;;
    .ant-col {
      padding: 0 20px;

      p {
        @extend .mb-0;
      }
    }
  }
}

.oraganizations-list {
  .ol-items-row {
    @extend .align-items-center;
    border-top: 1px solid #e8e8e8;
    padding: 1.3rem 0;
  }

  .ol-item {
    @extend .font-weight-medium;
    word-break: break-all;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.8);
  }

  .ol-items-row .ant-col {
    padding: 0 1.25rem;
  }

}