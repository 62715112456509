.view-landing-page {
  .vlp-header {
    background-color: var(--lpHeaderBgColor);
    color: var(--lpHeaderTextColor);
    @media screen and (max-width: 575px) {
      padding: 1rem;
      margin: 0.5rem;
    }
    @media screen and (min-width: 576px) {
      padding: 2.6rem 3rem;
      margin: 1.5rem 1.5rem 10px;
    }
    h2 {
      @extend .custom-fontFamily;
    }
    p {
      @extend .custom-fontFamily;
    }
  }

  .content-area {
    @media screen and (max-width: 575px) {
      padding: 1rem;
      margin: 0.5rem;
    }
  }
}

.custom-fontFamily {
  font-family: var(--lpFontFamily) !important;
}

.banner-bg {
  @extend .custom-fontFamily;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 300px;
  @media screen and (max-width: 575px) {
    padding: 1rem;
    margin: 0.5rem;
  }
  @media screen and (min-width: 576px) {
    margin: 0 1.5rem;
    padding: 2.9rem 3.2rem;
  }

  &::before {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .bunner-text {
    @extend .custom-fontFamily;
    max-width: 600px;
    position: relative;
    color: #fff;
    h2 {
      @extend .custom-fontFamily;
      font-size: 1.5rem;
      color: #fff;
    }
    p {
      @extend .custom-fontFamily;
    }
  }
}

.collapseList {
  width: calc(100% - 60px);
  h4 {
    @extend .font-weight-medium, .custom-fontFamily;
    font-size: 1.5rem;
    color: #4f4f4f;
  }
  .listIcons {
    @extend .d-flex, .align-items-center;
    h5 {
      @extend .d-flex, .justify-content-center, .align-items-center;
      min-width: 100px;
      font-size: 1rem;
      color: rgba(79, 79, 79, 0.3);
      & + h5 {
        margin-left: 1rem;
      }
      .eyeIcon {
        margin-right: 0.5rem;
        color: $text-primary;
      }
      .pauseIcon {
        color: rgba(79, 79, 79, 0.3);
        border: 2px solid rgba(79, 79, 79, 0.3);
        padding: 3px;
        margin-right: 0.5rem;
      }
    }
  }
}
.collapse-landing-page {
  .ant-collapse-header {
    background: #fff;
  }
}
.ant-collapse-item.ant-collapse-item-active {
  border: 1px solid $text-primary;
}

.ant-collapse-arrow {
  font-size: 1.5rem !important;
  color: $text-primary;
}
.download-selected-btn {
  @extend .d-flex, .ml-auto;
  border: 1px solid $text-primary !important;
  margin-bottom: 1.5rem;
}
