.sr-content-container {
  padding: 2rem;
  max-width: 1030px;
}
@media screen and (min-width: 577px) {
  .sr-content-container {
    padding: 2.938rem;
  }
}
@media screen and (min-width: 768px) {
  .sr-content-container {
    padding: 2rem 2rem 2rem 12.875rem;
  }
  .submit-responsive-wrapper {
    // > .ant-card {
    //   max-width: 1300px;
    //   margin: 0 auto;
    // }
    .ant-card-head {
      padding: 2.938rem;
      padding-left: 4.375rem;
      background-color: var(--lpHeaderBgColor);
      color: var(--lpHeaderTextColor);
    }
  }
}
.ant-card-head {
  background-color: var(--lpHeaderBgColor);
  color: var(--lpHeaderTextColor);
}

// landing page header style

.mph-content {
  &.organization-header{
    display: flex;
    gap: 20px;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      text-align: center;
    }
  }
  @extend .custom-fontFamily;
  // @media screen and (min-width: 576px) {
  //   display: flex;
  // }
  // @media screen and (max-width: 575px) {
  //   text-align: center;
  // }
  .organization-logo{
    display: block !important;
    img{
    max-height: 150px !important;
    max-width: 150px !important;
    }
  }
  .mph-logo {
    display: flex;
    align-items: center;
    gap: 24px;
    @media screen and (min-width: 992px) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 991px) {
      justify-content: center;
      margin-bottom: 1.125rem;
    }
    .img-wrap {
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 110px;
      width: 110px;
      justify-content: center;
      background-color: white;
      min-width: 110px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
    // .mph-logo-header{
    //   flex: 1;
    // }
  }

  h2 {
    @extend .font-weight-bold;
    font-family: inherit;
    font-size: 1.5rem;
    color: inherit;
    max-width: 700px;

    @media screen and (max-width: 575px) {
      margin: 0;
    }
  }
  p {
    @extend .font-weight-normal;
    font-family: inherit;
    font-size: 1rem;
    white-space: normal;
    color: inherit;
    margin-bottom: 19px;

    &.bold {
      color: inherit;
      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }
  }
}

.submit-responsive-wrapper {
  @media screen and (min-width: 992px) {
    padding: 2.75rem;
  }

  .ant-card-head {
    padding: 66px 24px;

    .ant-card-head-title {
      padding: 0;
      white-space: normal;
    }
  }
  .ant-card-body {
    @extend .p-0;
    // max-width: 778px;
  }
  .horizontal-border {
    color: #e0e0e0;
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .sr-question-content {
    @extend .custom-fontFamily;
    padding: 32px;
    background-color: #f8f8f8;
    border-radius: 12px;
    margin-bottom: 1.5rem;
    p {
      font-family: inherit;
      font-weight: 300;
      font-size: 1.125rem;
      color: #737373;
    }
    h2 {
      @extend .font-weight-bold;
      font-family: inherit;
      font-size: 1.5rem;
      text-transform: uppercase;
      color: var(--lpTextPrimary);
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 0;
    }
    h4 {
      font-family: inherit;
      font-weight: 500;
      font-size: 1rem;
      text-transform: uppercase;
      color: #404040;
      margin-bottom: 0;
    }
  }
  .sr-form {
    @extend .custom-fontFamily;
    .record-video-wight {
      img.vid-count-down {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 10;
      }
      .ant-form-item-control-input-content {
        @extend .d-flex, .justify-content-center, .position-relative;
        background: #f8f8f8;
        border-radius: 12px;
        overflow: hidden;
        // overflow: hidden; undo of portrait feature
        // border: 1px solid rgba(0, 0, 0, 0.4);
        .video-controls,
        .video-rerecord-btn {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }
        .video-frame {
          width: 100%;
        }

        .start-recording {
          @extend .d-flex, .justify-content-center;
          padding: 2rem;
          flex-direction: column;
          .video-start-icon {
            font-size: 2.875rem;
            color: var(--lpButtonBgColor);
            margin-bottom: 1.375rem;
          }
          p {
            @extend .font-weight-medium, .text-center;
            font-family: inherit;
            font-size: 1rem;
            color: #000000;
            margin-bottom: 0.625rem;
            text-align: center;
          }
          .recording-icon {
            @extend .position-relative, .d-flex, .justify-content-center, .align-items-center;
            border: 1px solid rgba(0, 0, 0, 0.5);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin: 0 auto;

            &:before {
              @extend .position-absolute;
              content: "";
              background: #d22f27;
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .sr-chackbox {
      .ant-form-item-control-input {
        min-height: 25px;
      }
      padding-bottom: 2rem;
      .ant-checkbox-wrapper {
        @extend .custom-fontFamily;
        @extend .font-weight-medium, .d-flex, .align-items-start;
        font-size: 0.875rem;
        color: #595959;

        span {
          font-family: inherit;
          padding-right: 0;
          top: 2px;
          @media screen and (max-width: 575px) {
            // margin-bottom: 1.5rem;
          }
        }
      }
    }
    .sr-submit-btn {
      height: 48px;
      background: var(--lpButtonBgColor);
      color: var(--lpButtonTextColor);
      border-color: var(--lpButtonBgColor);
      border-radius: 2px;
    }
  }
}

//  submit-response-next-level

.submit-response-next-level {
  @extend .custom-fontFamily;
  max-width: 600px;
  margin: 0 auto;
  .sr-video {
    video {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  .sr-question {
    @extend .custom-fontFamily;
    h5 {
      @extend .font-weight-bold;
      font-family: inherit;
      font-family: inherit;
      font-size: 18px;
      color: #828282;
    }
    p {
      @extend .font-weight-normal;
      font-family: inherit;
      font-family: inherit;
      font-size: 26px;
      color: #000000;
    }
  }
  .sr-submit-btn {
    font-family: inherit;
    max-width: 300px;
    width: 100%;
    height: 40px;
    margin: 3rem auto;
    display: inherit;
    background: var(--lpButtonBgColor);
    border-color: var(--lpButtonBgColor);
    color: var(--lpButtonTextColor);
  }
}

//  submit respons susses card style

.sr-success-message {
  max-width: 400px;
  background: #fff;
  margin: 0 auto;
  .ant-card-body {
    padding: 2rem !important;
    .ant-image {
      display: block;
      margin: 0 auto;
      margin-bottom: 1.5rem;
    }
    h3 {
      font-weight: bold;
      font-size: 19px;
      text-align: center;
      color: #000000;
    }
    p {
      max-width: 500px;
      margin: 16px auto 0;
    }
    .sr-success-message-btn {
      width: 100%;
      height: 45px;
      margin-top: 1.5rem;
      background: var(--lpButtonBgColor);
      border-color: var(--lpButtonBgColor);
      color: var(--lpButtonTextColor);
      font-weight: 600;
      max-width: 250px;
    }
  }
}
.success-message-icon {
  @extend .d-flex, .justify-content-center;
  font-size: 70px;
  margin-bottom: 1.5rem;
  color: var(--lpButtonBgColor);
}

//  video iframe style

.video-controls-wrap {
  .video-progress-bar {
    width: 150px;
    background: #fff;
    height: 5px;
  }
  .video-progress-bar-and-icon {
    @extend .d-flex, .justify-content-center, .align-items-center;
    background: #201b1d;
    padding: 5px 10px;
    border-radius: 20px;
    margin-top: 0.5rem;
  }
  .pause-and-play-icon {
    @extend .d-flex, .justify-content-center, .align-items-center;
    background: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    margin-right: 0.5rem;
    color: red;
    cursor: pointer;
  }
  .video-progress-bar span {
    @extend .d-block;
    background: red;
    height: 100%;
  }
  .volume-icon {
    margin-left: 0.5rem;
    img {
      width: 12px;
      cursor: pointer;
    }
  }
}
.vc-btn-wrap {
  @extend .d-flex, .justify-content-center;
  margin-top: 0.3rem;
  .vc-btn {
    // border-radius: 20px;
    font-size: 12px;
    background: #fff;
    & + .vc-btn {
      margin-left: 0.3rem;
    }
    &:hover {
      color: #000;
      border-color: #000;
    }
  }
}
.vjs-icon-av-perm::after {
  @extend .custom-fontFamily;
}
.ant-upload {
  @extend .custom-fontFamily;
  span {
    font-family: inherit;
  }
}
.megilla-input-submit-response {
  @extend .custom-fontFamily;
}
.megilla-input-submit-response-email {
  @extend .custom-fontFamily;
}
.submit-responsive-card {
  border: 0;

  @media screen and (min-width: 992px) {
    display: flex;
  }
  .ant-card-head {
    @media screen and (min-width: 992px) {
      width: 450px;
    }
  }
  .ant-card-body {
    @media screen and (min-width: 992px) {
      width: calc(100% - 450px);
    }
    padding: 24px !important
    ;
  }
  .ant-alert-with-description {
    padding: 10px 17px 10px 17px;
    color: #575757;
    margin-bottom: 3.25rem;
    border: transparent;
    .ant-alert-icon {
      margin-right: 17px;
      font-size: 20px;
    }
  }
}
.desktop-copyright {
  margin-top: 2.75rem;
  @media screen and (max-width: 991px) {
    display: none !important;
  }
}
.mobile-copyright {
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}
.record-video-wight {
  .ant-upload-select {
    & > span {
      display: flex;
      align-items: center;
      gap: 4px;
      @media screen and (max-width: 575px) {
        font-size: 10px;
      }
    }
  }
}
.upload-btn-link {
  @media screen and (max-width: 575px) {
    font-size: 10px;
  }

  span {
    color: var(--lpTextPrimary);
  }
}
.terms-condition-link {
  a {
    color: var(--lpTextPrimary);
  }
}
