.guest-layout {
  @extend .w-100, .p-0, .m-0, .d-flex, .bg-white;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .gl-banner {
    position: relative;
    background: url("/images/megilla_homepage_image.png") no-repeat center;
    background-size: cover;

    @media screen and (min-width: 768px) {
      width: 46.25%;
      max-width: 666px;
      min-height: 100vh;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      min-height: 50vh;
    }
    .gl-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media screen and (min-width: 768px) {
        width: 200px;
      }
      @media screen and (max-width: 767px) {
        width: 150px;
      }
    }
  }
  .gl-form {
    @extend .w-100, .m-auto;
    padding: 2rem;
    @media screen and (min-width: 768px) {
      width: 50%;
      max-width: 400px;
    }
    h2 {
      @extend .font-weight-bold, .mt-0;
      font-size: 1.875rem;
      color: rgba(0, 0, 0, 0.87);
    }
    p {
      font-size: 1.125rem;
      color: #8692a6;
      margin-top: 0.625rem;
      margin-bottom: 3.063rem;
    }

    .forgot-password {
      @extend .font-weight-medium;
      font-size: 0.75rem;
    }

    .ant-btn {
      height: 48px;
      border-radius: 2px;
    }
  }
}
.ant-form-item-label label {
  @extend .font-weight-bold;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}
.megilla-form-field {
  @extend .font-weight-medium;
  font-size: 0.875rem;
  color: #8692a6;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.megilla-input {
  @extend .megilla-form-field;
  min-height: 3rem !important;
}
.megilla-textarea textarea {
  @extend .megilla-form-field;
  min-height: 100px;
  max-height: 300px !important;
  overflow-x: hidden
}
.megilla-textarea{
  @extend .megilla-form-field;
  min-height: 100px;
  max-height: 300px;
}
.megilla-select-selector {
  .ant-select-selector {
    @extend .megilla-input, .align-items-center;
  }
}

.res-ftr {
  gap: 8px;
}
