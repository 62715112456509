.user-settings {
  .ant-divider-horizontal {
    width: calc(100% + 3rem);
    margin: 24px -24px;
  }

  h3 {
    @extend .font-weight-semibold;
    font-size: 1.25rem;
    color: #000000;
    margin-bottom: 0;
  }

  h5 {
    @extend .font-weight-semibold;
    font-size: 1rem;
    color: #000000;
  }


  .us-color {
    .us-color-text {
      @extend .font-weight-medium;
    }
    .us-color-box {
      width: 32px;
      height: 32px;
      border-radius: 4px;
    }
  }
}
.color-picker-popover {
  .ant-popover-inner {
    border-radius: 12px;
  }
  .ant-popover-inner-content {
    box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
    border-radius: 12px;
    padding: 1.063rem;

    .sketch-picker {
      border: 0 !important;
      box-shadow: none !important;
      width: 350px !important;
      padding: 0 !important;
    }
  }
  .flexbox-fix {
    input {
      @extend .w-100, .text-center;
      height: 40px;
      border: 1px solid #e5e8e8 !important;
      border-radius: 8px;
      font-size: 1rem !important;
      color: #111111;
      box-shadow: none !important;
      // text-align: center;
    }
  }
}

// accounts scss

.account-setting {
  border: 0;

  h3 {
    @extend .font-weight-semibold;
    font-size: 1.75rem;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 0 0.375rem;
    line-height: 1;
  }
}
.accounts-image-upload {
 
  @media screen and (min-width: 768px) {
     padding-left: 1.563rem;
  }
  .ant-upload-picture-card-wrapper {
    @extend .text-center;
    // text-align: center;

    .ant-upload-list-picture-card {
      @extend .d-flex, .justify-content-center, .align-items-center;
      flex-direction: column;
    }
  }
  .ant-upload-list-item-list-type-picture-card {
    border: 0;
    .ant-upload-list-item-info {
      border-radius: 50%;
    }
  }
  .ant-upload-select-picture-card {
    height: auto;
    border: 0;
  }
}

.image-upload-btn {
  border: 1px solid $text-primary !important;
}
