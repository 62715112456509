.rooms-cards {
  .ant-card {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;

    .ant-card-cover {
      padding: 0.5rem;
      padding-bottom: 0;
    }
  }
}

.search-invoices {
  width: 60%;
  .room-selector {
    .ant-select-show-search {
      width: 100%;
    }
  }
}

.rooms-card-heading {
  max-width: 598px;
  border: 0;
  h3 {
    @extend .font-weight-bold;
    font-size: 1.75rem;
  }
  p {
    @extend .font-weight-normal;
    margin-bottom: 52px;
    font-size: 1rem;
    color: #373737;
  }
}
.search-invoices-main {
  @extend .d-flex;
  margin-bottom: 2.25rem;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    .search-invoices-btn {
      width: 100%;
      .ant-radio-group-outline {
        margin-left: 0 !important;
        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
        label.ant-radio-button-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          margin-top: 0;
        }
      }
    }
  }

  .search-invoices .ant-input-affix-wrapper {
    // max-width: 256px;
    height: 32px;
    // border: 1px solid #e8e8e8;
    border-radius: 2px;
    color: #bfbfbf;

    input {
      @extend .font-weight-medium;
      font-size: 0.875rem;
    }
  }
}
.search-invoices-btn {
  @extend .ml-auto;
  .active {
    border: 1px solid $text-primary;
    color: $text-primary;
  }
  .ant-radio-group-outline {
    display: flex;
    margin-left: 10px;
  }
  button {
    @extend .font-weight-medium;
    margin-left: 0.625rem;
    min-width: 91px;
    height: 34px;
    font-size: 0.875rem;
    color: #595959;
  }
}

.card-title-icon {
  @extend .d-flex, .font-weight-semibold;
  font-size: 1rem;
}
.card-image {
  .card-icon {
    // @extend .postion-absolate;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.9);
    padding: 4px 10px;
    border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    .share-icon {
      color: #ff8c68;
      margin-left: 0.75rem;
    }
    .ant-icon {
      cursor: pointer;
      @extend .text-primary;
      margin-right: 1rem;
      cursor: pointer;
      transition: all 0.5s;
      &.delete {
        color: #ff4d4f !important;
        padding-right: 0;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.view-rooms-details-btn {
  @extend .d-flex;
  align-items: center;
  // margin-top: 1.5rem;

  button {
    @extend .font-weight-semibold;
    font-size: 0.75rem;
  }
  .switch-or-tage {
    @extend .ml-auto;
    text-align: right;
    .success {
      color: #6fcf97;
    }
    p {
      font-size: 10px;
      color: #d0d0d0;
      margin-bottom: 0;
    }
  }
}

// empty-rooms scss

.rooms-empty-card {
  @extend .d-flex, .justify-content-center, .align-items-center, .w-100, .text-center;
  min-height: calc(100vh - 220px);
  flex-direction: column;
  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
  @media screen and (max-width: 767px) {
    padding: 1rem;
  }

  .empty-rooms-img {
    width: 284px;
  }

  p {
    @extend .font-weight-medium, .mb-0, .custom-fontFamily;
    max-width: 410px;
    color: #000000;
    padding-top: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

// create new rooms scss

.form-input-tags {
  .ant-select-selector {
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    min-height: 48px;
  }
}
.select-templete-container .ant-form-item-control-input-content {
  @extend .d-flex;
  .select-templete {
    margin-right: 1rem;
    .layout-outlined-icon {
      font-size: 5.2rem;
    }
    svg {
      rect {
        color: #fff;
      }
    }
  }
  .select-templete.active {
    border-color: $text-primary;
    .layout-outlined-icon {
      color: $text-primary;
      svg {
        g path {
          fill: $text-primary;
        }
        rect {
          color: #fff;
          stroke: $text-primary;
        }
      }
    }
  }
}
.make-it-public-switch {
  .public-switch .ant-form-item-control-input-content {
    @extend .d-flex, .align-items-center;
    margin-top: 2.125rem;

    p {
      @extend .font-weight-semibold;
      font-size: 1.125rem;
      color: #828282;
      margin-right: 1.313rem;
      margin-bottom: 0;
    }
  }
}
.picture-outlined-icon {
  color: $text-primary;
  font-size: 2.8rem;
}

// rooms questions list
.question-list-filter {
  @extend .d-flex, .align-items-center;
  padding-top: 1.688rem;
  padding-bottom: 1.563rem;
  border: 1px solid #e0e0e0;

  .search-input,
  .select-option {
    height: 32px;
  }

  .search-input {
    width: 256px;
    margin-left: 1.688rem;
    margin-right: 0.563rem;
  }
  .select-option {
    width: 184px;
    margin-left: 1.875rem;
  }
}
.question-list-header {
  @extend .d-flex, .justify-content-between;
  margin: 2rem 3rem 2.5rem;
  h3 {
    @extend .font-weight-semibold;
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 0;
  }
  button {
    width: 150px;
    height: 31px;
  }
}
.question-list-items {
  margin-left: 3rem;
  margin-right: 3rem;

  .question-list-links {
    @extend .d-flex;
  }
  .response-number {
    @extend .d-flex, .align-items-center, .justify-content-center;
    width: 30px;
    height: 30px;
    border: 1px solid $text-primary;
    border-radius: 50%;
    color: $text-primary;
    margin-right: 0.625rem;
  }
  .list-button {
    @extend .d-flex, .align-items-center;
    min-height: 30px;
  }
}
.room-card {
  @extend .position-relative;
  // height: calc(100% - 20px);
  padding-bottom: 62px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  .card-image {
    @extend .position-relative;
    &::before {
      content: " ";
      padding-top: 65%;
      display: block;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .ant-card-body {
    padding: 1rem;
    .card-title-icon {
      @extend .ellipse;
      margin-bottom: 1rem;
      .card-title {
        @extend .font-weight-semibold, .ellips-lines;
        -webkit-line-clamp: 1;
        font-size: 1rem;
        color: #212121;
      }
    }
    .rc-description {
      @extend .ellips-lines, .ellipse;
      -webkit-line-clamp: 2;
      margin-bottom: 1.5rem;
      min-height: 50px;
      margin-bottom: 0;
    }
    .view-rooms-details-btn {
      .rooms-details {
        @extend .p-0;
        min-width: 69px;
        height: 36px;
      }
    }
  }

  .ant-card-cover {
    padding: 8px;
    padding-bottom: 0;
    width: 100%;
  }

  .view-rooms-details-btn {
    position: absolute;
    bottom: 24px;
    justify-content: space-between;
    width: calc(100% - 32px);
  }
}

//  rooms-details page scss

// .rooms-details {
//   padding: 46px;
// }
.rooms-details {
  .question-videos-list {
    @media screen and (max-width: 991px) {
      // padding: 0 !important;
      margin-top: 1rem;
    }
  }
}

// view rooms question list
.rooms-questions-sidebar {
  + .rooms-questions-sidebar {
    padding-top: 1rem;
  }

  li {
    @extend .font-weight-semibold;
    // box-shadow: (0px 0px 1px rgba(12, 26, 75, 0.2)), (0px 1px 3px rgba(50, 50, 71, 0.1));
    border: 1px solid rgba(50, 50, 71, 0.1) !important;
    padding: 1rem;
    display: flex;

    font-size: 14px;
    color: #4f4f4f;
    .rq-icons {
      padding-left: 0.5rem;
    }
  }
}
.question-videos-header {
  .qvh-switch {
    @extend .d-flex, .align-items-center, .justify-content-end;
    margin: 0 0 0 auto;
    h6 {
      @extend .font-weight-medium;
      font-size: 12px;
      color: #525252;
    }
    .landing-page-btn {
      @extend .text-primary, .font-weight-medium;
      font-size: 14px;
      border: 1px solid $text-primary;
    }
  }
  .qvh-title {
    @media screen and (min-width: 991px) {
      margin-top: -1.5rem;
    }
    // margin-top: -1.5rem;

    border-bottom: 1px solid #e0e0e0;
    h5 {
      @extend .font-weight-semibold, .custom-fontFamily;
      font-size: 1rem;
      color: #4f4f4f;
    }
    h3 {
      @extend .font-weight-semibold, .text-primary, .custom-fontFamily;
      font-size: 1.5rem;
      margin-top: 14px;
      margin-bottom: 32px;
    }
  }
}
.question-videos-filter {
  @media screen and (min-width: 601px) {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
  }
  .qvf-download-btn .ant-badge {
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-top: 0.5rem;
    }
    button {
      @extend .font-weight-medium, .text-primary;
      font-size: 14px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
.qvf-fields {
  @media screen and (max-width: 600px) {
    .ant-radio-group {
      width: 100%;
    }
  }
  label.ant-radio-button-wrapper {
    @media screen and (min-width: 1200px) {
      margin-right: 11px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      + label.ant-radio-button-wrapper {
        margin-top: 0.5rem;
      }
    }

    min-width: 91px;
    text-align: center;
    // border: 1px solid #e8e8e8;
    border-radius: 2px;
  }
}
.video-card {
  .thumbnail-image {
    @extend .position-relative;
    &::before {
      content: " ";
      display: block;
      padding-top: 56%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
  .check-circle-icon {
    color: rgba(84, 170, 108, 1) !important;
  }
  .close-circle-icon {
    color: rgba(236, 97, 97, 1) !important;
  }
  .ant-card-cover {
    @extend .position-relative;
    cursor: pointer;
    overflow: hidden;
    &::after {
      content: " ";
      display: block;
      padding: 56%;
    }
    img {
      transition: all 0.5s;
    }

    &:hover {
      &:after {
        background: rgba(0, 0, 0, 0.55);
      }
      img {
        transform: translate(-50%, -50%) scale(1.1);
      }
      .play-icon {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &:after {
      transition: all 0.5s;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
    .checkbox-icon {
      position: absolute;
      left: 8px;
      top: 8px;
      z-index: 1;

      .ant-checkbox-inner {
        background: transparent;
      }
    }
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      font-size: 28px;
      color: #fff;
      z-index: 1;
      transform: translate(-50%, -50%);
      transition: transform 0.5s;
    }
    .video-time-length {
      position: absolute;
      z-index: 1;
      width: auto;
      left: 8px;
      bottom: 8px;
      background: rgba(86, 86, 86, 0.9);
      padding: 0 8px;
      border-radius: 20px;
      color: #fff;
      font-size: 10px;
      font-weight: 300;
    }
  }
  .ant-card-body {
    @extend .position-relative;
    .video-downoled-btn {
      background: #fff;
      color: rgba(44, 171, 225, 1);
      width: auto;
      position: absolute;
      top: -14px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 8px;
      border: 1px solid #e9e9e9;
    }
    padding: 1rem;
    h3 {
      @extend .font-weight-semibold;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      margin-bottom: 10px;
    }
    h5 {
      @extend .font-weight-medium;
      color: rgba(89, 89, 89, 1);
      font-size: 10px;
      .clander-icon {
        color: rgba(191, 191, 191, 1);
      }
    }
  }
}

// create room page style

.cr-image-banner {
  .dragger-img-box {
    .remove-image-icon {
      z-index: 1;
      position: absolute;
      top: 10px;
      color: rgba(91, 91, 91, 0.85);
      border: 0;
      right: 10px;
      min-width: 36px;
      min-height: 36px;
    }
  }

  .ant-upload-btn {
    @extend .p-0;
  }
  .upload-image-style {
    @extend .position-relative;
    min-height: 330px;
    overflow: hidden;
    &::after {
      content: "";
      display: block;
      padding: 20%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.rooms-public-switch {
  .public-switch {
    flex-direction: row !important;
    align-items: center;
    label {
      @extend .pb-0;
      margin-right: 1rem;
    }
  }
}

//  rooms question list sidbar

.question-list-wrap {
  h3 {
    @extend .font-weight-semibold;
    font-size: 18px;
    color: #16192c;
  }
  padding: 1rem;
  box-shadow: (0px 0px 1px rgba(12, 26, 75, 0.2)), (0px 1px 3px rgba(50, 50, 71, 0.1));
  .rooms-questions-sidebar {
    li.active {
      @extend .text-primary;
      border: 1px solid $text-primary !important;
      .rq-icons {
        color: #f2994a;
      }
    }
  }

  // create room page style

  .cr-image-banner {
    .dragger-img-box {
      .remove-image-icon {
        z-index: 1;
        position: absolute;
        top: 10px;
        color: rgba(91, 91, 91, 0.85);
        border: 0;
        right: 10px;
        min-width: 36px;
        min-height: 36px;
      }
    }

    .ant-upload-btn {
      @extend .p-0;
    }
    .upload-image-style {
      @extend .position-relative;
      min-height: 330px;
      overflow: hidden;
      &::after {
        content: "";
        display: block;
        padding: 20%;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .rooms-public-switch {
    .public-switch {
      flex-direction: row !important;
      align-items: center;
      label {
        @extend .pb-0;
        margin-right: 1rem;
      }
    }
  }
}

//  rooms question list sidbar

.question-list-wrap {
  h3 {
    @extend .font-weight-semibold;
    font-size: 18px;
    color: #16192c;
  }
  padding: 1rem;
  box-shadow: (0px 0px 1px rgba(12, 26, 75, 0.2)), (0px 1px 3px rgba(50, 50, 71, 0.1));
  .rooms-questions-sidebar {
    li.active {
      @extend .text-primary;
      border: 1px solid $text-primary !important;
      .rq-icons {
        color: #f2994a;
      }
    }
  }
}

.buttonBreakToNewLine {
  background: #fff;
  padding: 0 24px 20px;
  button + button {
    margin-left: 0.5rem;
  }
  @media screen and (max-width: 991px) {
    button {
      margin-top: 1rem;
    }
  }
}

.card-actions-wrap {
  padding-bottom: 0;
  .ant-card-actions {
    li,
    li .anticon {
      color: $text-primary;
      span.btn-text {
        font-size: 10px;
      }
    }
  }
}

.qrs-ai-seach {
  max-width: 300px;
  width: 100%;
}
