.heading-or-btn {
  @extend .d-flex, .align-items-center, .justify-content-between;
  border-bottom: 1px solid #e9e9e9;

  h3 {
    border: 0 !important;
  }
}
.custom-fields-btn {
  @extend .font-weight-medium, .d-flex, .align-items-center, .justify-content-center, .ml-auto;
  color: $text-primary;
  border-color: $text-primary;
  max-width: 137px;
  height: 32px;
  font-size: 14px;
}
// question list

.question-list-container {
  // @extend .bg-white, .mt-3, .py-3;
  .vl-list-item {
    .vli-chackbox-wrap {
      width: auto;
      margin-right: 51px;
      .vli-star-icon {
        padding: 0;
      }
    }
  }

  .questions-list-filters {
    @extend .px-3;
    padding-top: 9px;
    padding-bottom: 27px;
    border-bottom: 1px solid #e0e0e0;
    .input-field {
      margin-right: 23px;
    }
    .input-field,
    .input-field-tage .ant-select-selector {
      width: 256px;
      height: 32px;
      border: 1px solid #e8e8e8;
    }
    .input-field-tage .ant-select-selector {
      .ant-select-selection-placeholder {
        color: #595959;
      }
    }
  }
}
.list-star-outlined {
  @extend .text-white, .d-flex, .align-items-center, .justify-content-center;
  width: 34px;
  height: 34px;
  font-size: 15px;
  color: #fff;
  background: Orange;
  border-radius: 50%;
  margin-right: 48px;
}
.list-items {
  @extend .d-flex, .align-items-center;

  .list-chackbox {
    margin-right: 48px;
  }
  .question-title {
    @extend .font-weight-semibold;
    margin-right: 32px;
    margin-bottom: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    width: 115px;
  }
}

.questions-list-title {
  @extend .font-weight-semibold;
  color: #000000;
  font-size: 20px;
  margin-bottom: 22px;
  margin-top: 10px;
}
.question-edit-remove {
  @extend .ml-auto, .d-flex, .justify-content-between, .align-items-center;
  width: 150px;
}
.question-description {
  @extend .font-weight-medium, .mb-0;
  font-size: 20px;
  color: #212121;
  width: calc(100% - 443px);
}
.form-custom-field {
  .anticon-delete {
    color: red;
    @extend .d-flex, .justify-content-end;
    margin-bottom: 0.5rem;
  }
}
.modal-listing {
  .ant-modal-close {
    @extend .d-none;
  }
  .ant-modal-header {
    border: 0;
    padding: 24px 24px;

    .ant-modal-title {
      @extend .font-weight-semibold;
      font-size: 20px;
      color: #000000;
    }
  }
  .list-filter {
    padding-top: 24px;
    border-bottom: 1px solid #e9e9e9;
    border-top: 1px solid #e9e9e9;
    margin-bottom: 3.5rem !important;
    margin-top: -24px !important;
  }
  .ant-modal-footer {
    @extend .d-flex, .justify-content-between;
    border: 0;
  }
  .ant-modal-footer button.ant-btn {
    min-width: 180px;
    height: 48px;
    margin-top: 8px;
    margin-bottom: 42px;
  }
}
.select-field {
  padding: 0.5rem;
  .sf-title {
    @extend .font-weight-semibold;
    font-size: 12px;
    color: darken($text-primary, 10%) ;
  }
  .sf-checkbox {
    margin-top: 1rem;
    margin-bottom: 1.3rem;
  }
  .sf-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 47%;
    }
  }
  .megilla-select-selector .ant-select-selection-item {
    @extend .font-weight-semibold;
    color: #000000;
    font-size: 16px;
  }
}

// custom question

.ecf-podal {
  h3 {
    @extend .text-primary, .font-weight-medium;
    text-transform: uppercase;
    // font-weight: 600;
    font-size: 12px;
  }
  .ant-modal-body {
    @extend .pb-0;
  }
  .ant-modal-footer {
    @extend .d-flex, .justify-content-between;
    border-top: 0;
  }
}

// question list sidbar style

.question-list-wrap {
  .scrollbar-container {
    @media screen and (min-width: 992px) {
          height: 600px !important;
    }
     @media screen and (max-width: 991px) {
          height: auto !important;
          max-height: 300px !important;
    }

  }
}
