.video-js,
.vjs-control-bar {
  background-color: transparent !important;
    // border: 2px dashed rgba(0,0,0,.4);
}
.video-js {
  font-size: 14px;
  button.vjs-record-button {
    height: 44px;
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    width: 130px;
    background: #fff;
    color: #000;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    // transform: translateX(-50%);
    &.vjs-icon-record-start:before {
      font-size: 12px;
      line-height: normal;
      margin-right: 5px;
      color: #ff0000;
    }
    .vjs-control-text {
      position: static;
      height: inherit;
      clip: inherit;
      width: auto;
      height: auto;
      line-height: 2;
      display: block;
      font-family: arial;
    }
  }
}
.vjs-record.video-js .vjs-control.vjs-record-indicator {
  right: 3.5rem;
}
.video-js {
  .vjs-control-bar {
    top: 0;
    height: 100%;
    .vjs-progress-control {
      width: 250px;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px 0;
      bottom: 5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      display: flex;
      .vjs-slider {
        background: #fff;
        margin: 0 5px;
        &-bar {
          background: var(--lpButtonBgColor);
          &:before {
            display: none;
          }
        }
      }
      .vjs-play-progress {
        .vjs-time-tooltip {
          display: none !important;
        }
      }
    }
    .vjs-play-control {
      position: absolute;
      height: 24px;
      background: rgba(0, 0, 0, 0.5);
      bottom: 5rem;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -139px;
      width: 28px;
      display: flex;
      align-items: center;
      border-radius: 1rem 0 0 1rem;
      .vjs-icon-placeholder:before {
        margin-left: 3px;
        margin-top: 2px;
        font-size: 20px;
        line-height: normal;
      }
    }
    .vjs-volume-panel {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      bottom: 5rem;
      height: auto;
      right: 50%;
      transform: translateX(-50%);
      height: 24px;
      margin-right: -167px;
      width: 28px;
      border-radius: 0 1rem 1rem 0;
      button.vjs-mute-control {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .vjs-icon-placeholder:before {
          line-height: normal;
          font-size: 20px;
          margin: 2px 0 0 1px;
        }
      }
      .vjs-volume-control {
        padding: 0;
        width: 24px;
        border-radius: 1rem;
        background: rgba(0, 0, 0, 0.5);
        left: inherit;
        right: 7px;
        margin: -0.5rem 0 0 0;
      }
      &.vjs-hover,
      &.vjs-slider-active {
        .vjs-volume-control {
          left: inherit !important;
        }
      }
    }
    .vjs-time-control {
      // background: rgba(0, 0, 0, 0.5);
      background: #fff;
      color: #000;
      height: auto;
      align-self: flex-start;
      position: absolute;
      left: 50%;
      bottom: 1.5rem;
      line-height: 44px;
      &.vjs-current-time,
      &.vjs-duration {
        width: 64px;
        text-align: center;
        padding: 0;
      }
      &.vjs-current-time {
        border-radius: 2rem 0 0 2rem;
        display: block;
        margin-left: -136px;
      }
      &.vjs-duration {
        border-radius: 0 2rem 2rem 0;
        display: block;
        margin-left: -68px;
      }
      &.vjs-time-divider {
        padding: 0;
        width: 4px;
        min-width: inherit;
        display: block;
        text-align: center;
        margin-left: -72px;
      }
    }
    .vjs-fullscreen-control {
      right: 0px;
      position: absolute;
    }
  }
}

.vjs-record .vjs-device-button.vjs-control {
  background: var(--lpButtonBgColor);
  border-radius: 50%;
  margin: -15px 0 0 0;
  transform: translate(-50%, -50%);
  font-size: 26px;
  // background-image: url("../images/record-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  &::before {
    @extend .custom-fontFamily;
    display: block;
    content: "REC";
    border: 0;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: auto;
    height: auto;
    font-family: arial;
    text-transform: uppercase;
    font-size: 4rem !important;
    font-weight: 600;
    @media screen and (max-width: 575px) {
      font-size: 25px !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      font-size: 2rem !important;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    height: 4.5em !important;
    width: 4.5em !important;
  }
  @media screen and (min-width: 576px) {
    height: 10em;
    width: 10em;
  }
  @media screen and (max-width: 575px) {
    height: 4.5em;
    width: 4.5em;
  }
}

.vjs-icon-av-perm {
  &::before {
    content: "";
    border-width: 0.8em 0.8em 0.8em 1.5em;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    width: 0;
    height: 0;
    display: none;
    position: absolute;
    top: 50%;
    left: 67%;
    transform: translate(-50%, -50%);
    font-size: inherit !important;
  }
  &::after {
    content: "Click here to start recording";
    color: #000;
    top: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Inter", sans-serif;
    white-space: nowrap;
    margin-top: 8px;
    font-size: 1rem;
    @media screen and (max-width: 575px) {
      margin-top: 0.5rem;
      font-size: 12px;
    }
  }
}

.vjs-hidden-custom {
  display: none !important;
}

.prompt-msg {
  color: #FFF;
  height: 100%;
  width: 100vw;
  background: rgba(0,0,0,.8);
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 18px;
  font-weight: bold;
}

/* 
.video-js .vjs-control {
  width: 3em;
}
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
  width: 9em;
}


.video-js .vjs-time-control {
  padding-left: 0.3em;
  padding-right: 0.3em;
  min-width: inherit;
}

.icon-download-recording {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  cursor: pointer;
}

.icon-pause-recording,
.icon-resume-recording {
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
  margin: 13px 0 0 1em;
}
.vjs-progress-control.vjs-control {
  // display: block !important;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 100%;
  height: auto;
  z-index: 3;
} */

/* .video-js:not(.vjs-live):not(.vjs-ended):not(.vjs-playing) .vjs-control-bar {
  display: none !important;
} */

// .vjs-has-started.vjs-user-active .vjs-progress-control,
// .vjs-has-started.vjs-live .vjs-progress-control {
//   display: none !important ;
// }
// .vjs-paused.vjs-live.vjs-user-active.vjs-playing .vjs-progress-control {
//   display: block !important;
// }

