.logo {
  height: 32px;
  background: #ffffff33;
  margin: "opx 16px";
}

.siteLayout .site-layout-background {
  background: #fff;
}
[data-theme="light"] .site-layout .site-layout-background {
  background: #ffff;
}
