body {
  font-family: "Inter", sans-serif;
}

img {
  max-width: 100%;
}
.custom-fontFamily {
  font-family: var(--lpFontFamily) !important;
}
.text-primary {
  color: $text-primary;
}

.font-weight {
  &-normal {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }
}

.ant-layout {
  & > .ant-layout-header {
    & + .ant-layout-content {
      .content-area {
        min-height: calc(100vh - 64px - 3rem);
      }

      & > .ant-page-header {
        & + .content-area {
          min-height: calc(100vh - 64px - 78px - 3rem);
        }
      }
    }
  }
}

.ant-layout {
  min-height: 100vh;

  &-sider {
    @extend .bg-white;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.15);
    margin-right: 1px;

    &-logo {
      max-width: 135px;
      margin: 30px auto;
    }
  }

  &-header {
    @extend .bg-white;
  }

  &.guest-layout {
    .ant-layout-content {
      @extend .d-flex, .align-items-center, .position-relative;
    }
  }
}

.site-page-header {
  margin-top: 2px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
}

.app-header-right {
  float: right;

  .loggedin-user-dd {
    & > a {
      @extend .font-weight-medium;
      color: #262626;

      .ant-avatar,
      .anticon {
        margin: 0 5px;
      }

      .anticon {
        &-down {
          color: #8c8c8c;
        }
      }
    }
  }
}

// sidebar style scss
@media screen and (max-width: 767px) {
  .sidebar-main {
    max-width: 60px !important;
    min-width: 60px !important;

    .ant-menu {
      width: 60px;

      .ant-menu-item {
        text-overflow: clip;

        span + span {
          display: none;
          // text-overflow: ;
        }
      }
    }
  }
}

.sidebar-main.ant-layout-sider-collapsed {
  max-width: 60px !important;
  min-width: 60px !important;

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-menu-inline-collapsed {
    width: 60px;
    background: transparent;
    color: #fff;
    border: 0;
  }
}

.ant-layout-header {
  @extend .position-relative;
  padding: 0 30px;
  z-index: 1;
}

.sidebar-main {
  .ant-layout-sider-logo {
    padding: 0 8px;
    text-align: center;
  }

  .ant-layout-sider-children {
    background: #001529;

    .ant-menu-inline {
      background: #001529;
      color: #fff;
      border: 0;

      li {
        &:active {
          background: transparent !important;
        }

        a {
          color: rgba(255, 255, 255, 0.65);

          &:hover {
            color: $text-primary;

            a {
              color: $text-primary;
            }
          }
        }
      }

      .ant-menu-item-selected {
        background-color: transparent;
        color: $text-primary;

        &::after {
          border: 0;
        }

        a {
          color: $text-primary;
        }
      }
    }
  }
}

.content-area-card {
  @extend .bg-white;
  height: 100%;
}

// accounts screen style scss

.accounts-wrapper {
  @extend .content-area-card;

  .accounts-heading {
    font-weight: bold;
    font-size: 28px;
    padding-top: 48px;
    margin-bottom: 44px;
  }

  .accounts-content {
    max-width: 480px;

    form {
      @extend .d-flex, .w-100;

      label {
        font-weight: 600;
        font-size: 16px;
        color: #212121;
      }

      input {
        height: 32px;
      }

      .accounts-details {
        width: 70%;
      }

      .accounts-profile {
        width: 40%;
        padding-left: 2rem;
      }
    }
  }
}

// breadcrumb-top-header scss

.megilla-page-header {
  margin-top: 2px;
}

.content-area {
  @extend .position-relative, .bg-white;
  min-height: calc(100vh - 220px);
  padding: 1.5rem;
  margin: 1.5rem;
  // @media screen and (min-width: 768px) {
  //   margin: 1.5rem;
  // }
  // @media screen and (max-width: 767px) {
  //   margin: 0.5rem;
  // }
}

// sidebar style scss

.sidebar-main.ant-layout-sider-collapsed {
  max-width: 60px !important;
  min-width: 60px !important;

  .ant-menu-inline-collapsed {
    width: 60px;
    background: transparent;
    color: #fff;
    border: 0;
  }
}

.ant-layout-header {
  @extend .position-relative;
  padding: 0 30px;
  z-index: 1;
}

.sidebar-main {
  .ant-layout-sider-logo {
    padding: 0 8px;
    text-align: center;
  }

  .ant-layout-sider-children {
    background: #001529;

    .ant-menu-inline {
      background: #001529;
      color: #fff;
      border: 0;

      li {
        &:active {
          background: transparent !important;
        }

        a {
          color: rgba(255, 255, 255, 0.65);

          &:hover {
            color: $text-primary;

            a {
              color: $text-primary;
            }
          }
        }
      }

      .ant-menu-item-selected {
        background-color: transparent;
        color: $text-primary;

        &::after {
          border: 0;
        }

        a {
          color: $text-primary;
        }
      }
    }
  }
}

.content-area-card {
  @extend .bg-white;
  height: 100%;
}

// accounts screen style scss

.accounts-wrapper {
  @extend .content-area-card;

  .accounts-heading {
    font-weight: bold;
    font-size: 28px;
    padding-top: 48px;
    margin-bottom: 44px;
  }

  .accounts-content {
    max-width: 480px;

    form {
      @extend .d-flex, .w-100;

      label {
        font-weight: 600;
        font-size: 16px;
        color: #212121;
      }

      input {
        height: 32px;
      }

      .accounts-details {
        width: 70%;
      }

      .accounts-profile {
        width: 40%;
        padding-left: 2rem;
      }
    }
  }
}

// breadcrumb-top-header scss

.megilla-page-header {
  margin-top: 2px;
}

.content-area {
  @extend .position-relative, .bg-white;
  // margin: 1.5rem;
  min-height: calc(100vh - 220px);
}

// .site-badge-count-4 .ant-badge-count {
//   color: #001529;
//   background-color: #fff;
//   box-shadow: 0 0 0 1px #d9d9d9 inset;
// }

// DynamicVirtualList style
.vl-list-item-wrap {
  padding-top: 1.5rem;
}

.vl-list-item {
  @extend .d-flex, .justify-content-between;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 12px;

  .vli-star-icon {
    width: 70px;

    .anticon-star {
      @extend .d-flex, .align-items-center, .justify-content-center;
      margin-left: 10px;
      width: 34px;
      height: 34px;
      background: #399e6bd1;
      border-radius: 50%;
      color: #fff;
    }
  }

  .vli-question-wrap {
    @extend .d-flex;
    @media screen and (min-width: 1200px) {
      width: 55%;
    }
    @media screen and (max-width: 1199px) {
      width: 90%;
    }
  }

  .vli-question {
    width: calc(100% - 70px);

    h3 {
      @extend .font-weight-bold;
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.85);
    }

    P {
      @extend .font-weight-medium;
      font-size: 1rem;
      color: #4f4f4f;
    }
  }

  .vli-actions {
    @extend .d-flex, .justify-content-between, .align-items-center;
    width: 45%;

    button {
      margin: 5px;
    }

    .response-values {
      // @extend .d-inline-flex, .justify-content-center, .align-items-center;
      // border: 1px solid #2cabe1;
      // border-radius: 50%;
      // color: #2cabe1;
      .ant-badge-count {
        @extend .d-flex, .justify-content-center, .align-items-center;
        background: transparent;
        color: $text-primary;
        border: 1px solid $text-primary;
        padding: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
}

// add existing question list style

.questions-list {
  .ant-select {
    width: 100%;
  }

  .ant-modal-body {
    padding: 1.5rem 2.1rem;
  }

  .vl-list-item {
    @extend .align-items-start;

    .vli-chackbox-wrap {
      @extend .d-flex;
      @media screen and (min-width: 992px) {
        width: 146px;
        margin-left: 13px;
        .vli-star-icon {
          padding: 0 3rem;
        }
      }
      @media screen and (max-width: 991px) {
        width: 85px;
        .vli-star-icon {
          padding: 0 1rem;
        }
      }
      .vli-checkbox {
        margin-top: 5px;
      }

      .vli-star-icon {
        // padding: 0 3rem;
        width: auto;

        .anticon-star {
          margin-left: 0;
        }
      }
    }

    .vli-content {
      @extend .d-flex, .align-items-start;

      // width: calc(100% - 216px);
      margin-top: 5px;

      @media screen and (min-width: 992px) {
        width: calc(100% - 216px);
        .vli-question-id {
          width: 100px;
        }
      }
      @media screen and (max-width: 991px) {
        width: calc(100% - 155px);
        .vli-question-id {
          width: 100px;
        }
      }

      .vli-question-id {
        margin-right: 1rem;
        // width: 157px;
      }
    }

    .vli-link {
      width: 70px;
      margin: 0 0 0 auto;
    }

    .vli-question-description {
      @media screen and (min-width: 992px) {
        width: calc(100% - 173px);
      }
      @media screen and (max-width: 991px) {
        width: calc(100% - 116px);
      }
    }
  }
  .ant-typography-single-line {
    white-space: normal;
  }

  .question-list-heading {
    @extend .font-weight-semibold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 0;
  }
}

//  content  ellipse
.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellips-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

// create question modal

.create-question-modal {
  background: #fff;

  .ant-modal-content {
    padding: 0 1rem;
    max-width: 800px;
    margin: auto;
    box-shadow: none;

    .ant-modal-header {
      @extend .px-0;
      padding-top: 2.5rem;

      .ant-modal-title {
        @extend .font-weight-semibold;
        font-size: 24px;
        color: #000000;
      }
    }
    .ant-modal-body {
      @extend .px-0;
      .fields-delete-icon {
        color: red;
        text-align: right;
      }
      .form-custom-field,
      .form-custom-field-checkbox {
        margin-bottom: 0;
      }
    }
    .ant-modal-footer {
      @extend .px-0;
      border: 0;
    }
  }
}

.rooms-questions-sidebar {
  .sidebar-question-list-ellipsis {
    width: calc(100% - 30px);
    .ant-typography-ellipsis {
      margin-bottom: 0;
    }
  }
}

// .questions-list {
//   .ps__thumb-x {
//     display: none;
//   }
// }

.questions-list-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 1rem;
  }
}

.loggedin-user-dd {
  .ant-dropdown-link {
    @extend .d-flex, .justify-content-center, .align-items-center;
  }
}

.ant-typography.ant-typography-ellipsis {
  margin-bottom: 0;
}

.list-dropdown-actions {
  @extend .d-flex, .justify-content-center, .align-items-center;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  border: 1px solid #f0f0f0;
}

// add existing question
// .questions-list {
//   overflow: auto;
//   .add-existing-question {
//     min-width: 768px;
//     overflow-x: visible;
//   }
// }

// .perfect-scrollbar-height {
//    position: relative;
//   width: 800px;
//   height: 400px;
// }

.li-action-links {
  display: flex;
  flex-wrap: wrap;
}

.li-action-links button {
  padding-right: 0;
}

//  list link copy modal
.link-copy-btn {
  background: #566789;
  border-radius: 0px 2px 2px 0px;
}

.modal-link-input {
  height: 48px;
  padding-right: 0;
  .link-copy-btn {
    background: #566789;
    border-radius: 0px 2px 2px 0px;
    color: #fff;
    padding: 16px;
    font-size: 1rem;
  }
}
.copy-link-modal {
  .ant-modal-header {
    padding-bottom: 0;
    border: 0;
    padding-top: 2rem;
  }
  .ant-modal-body {
    padding-top: 0.5rem;
  }
  .ant-modal-footer {
    padding-top: 0;
    border: 0;
  }
}
.video-modal-landing-page {
  @media screen and (min-width: 700px) {
    width: 60% !important;
  }
  .ant-modal-body {
    padding: 0 !important;
    background: black;
  }
  .ant-modal-close-x {
    background: rgba(219, 227, 228, 0.6);
    border-radius: 50%;
    color: #000;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0.5rem;
  }
}
.sider-footer-logo {
  position: fixed;
  bottom: 24px;
  left: 24px;
  // width: 200px;
  // text-align: center;
  // width: 150px;
  z-index: 1000;
  background-color: #001529;
  // margin: 100px auto;
}

.btn-bg-theme-color {
  background-color: var(--lpButtonBgColor) !important;
  border-color: var(--lpButtonBgColor) !important;
}
.btn-theme-color {
  color: var(--lpButtonBgColor) !important;
  background: transparent !important;
  border-color: var(--lpButtonBgColor) !important;
}

// .box {
//   position: absolute;
//   width: 100%;
//   height: 70vh;
//   background: radial-gradient(#fff, #757575);
//   overflow: hidden;
//   z-index: 1111;
// }

// .box:before {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 0;
//   transform: translateY(-50%);
//   width: 100%;
//   height: 5px;
//   background: #000;
// }

// .box:after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 5px;
//   height: 100%;
//   background: #000;
// }

// .circle {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 500px;
//   height: 500px;
//   border: 5px solid #fff;
//   border-radius: 50%;
//   z-index: 1;
// }

// .circle.circle2 {
//   width: 600px;
//   height: 600px;
// }

// .niddle {
//   position: absolute;
//   top: calc(50% - 2px);
//   left: 50%;
//   height: 4px;
//   width: 1200px;
//   background: #000;
//   animation: animate 1s linear infinite;
//   transform-origin: left;
// }

// @keyframes animate {
//   0% {
//     transform: rotate(-90deg);
//   }
//   100% {
//     transform: rotate(270deg);
//   }
// }

// .number {
//   position: absolute;
//   width: 100%;
//   height: 100%;
// }

// .number div {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   font-size: 30em;
//   font-weight: bold;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 0;
//   animation: animateNumber 3s linear infinite;
// }

// @keyframes animateNumber {
//   0%,
//   10% {
//     opacity: 1;
//   }
//   10.01%,
//   100% {
//     opacity: 0;
//   }
// }

// .number div:nth-child(1) {
//   animation-delay: 0s;
// }

// .number div:nth-child(2) {
//   animation-delay: 1s;
// }

// .number div:nth-child(3) {
//   animation-delay: 2s;
// }

.react-player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.video-card-tag-icon,
.question-card-new-video-icon,
.room-card-new-video-icon {
  position: absolute;
  display: inline-block;
  color: #fff;
  width: auto;
  background: #faad14;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-card-new-video-icon,
.room-card-new-video-icon {
  font-size: 13px;
  width: 20px;
  height: 20px;
}
.video-card-tag-icon {
  right: 5px;
  top: 5px;
  font-size: 10px;
  width: 30px;
  height: 30px;
  z-index: 1;
}
.question-card-new-video-icon {
  top: -10px;
  left: -10px;
}
.room-card-new-video-icon {
  top: -10px;
  left: -10px;
}
.delete-user-fileds {
  text-align: right;
  color: red;
  font-size: 20px;
  line-height: 1;
}
.add-user-fields-wrapper {
  border: 1px solid #c9c8c8;
  padding: 1rem 1rem 0 !important;
  margin-bottom: 1.5rem;
  .delete-user-fileds {
    background: #f9cccc;
    padding: 5px 5px;
    border-radius: 4px;
    max-width: 30px;
    margin-left: auto;
  }
}

.r-question-list {
  // .question-list-wrap {
  @media screen and (min-width: 992px) {
    .responsive-question-list {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 1123;
    min-height: 100vh;
    top: calc(100% - 50px);
    padding: 0 !important;
    overflow: hidden;
    &.open-question {
      top: 0;
      background-color: #fff;
      .question-list-wrap {
        margin: 60px 1rem 0;
        width: calc(100% - 32px);
        .scrollbar-container {
          max-height: calc(100vh - 118px) !important;
        }
      }
    }
    .responsive-question-list {
      position: absolute;
      top: 0;
      background: #2cace2;
      left: 0;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 24px;
      color: #fff;
      .question {
        width: calc(100% - 150px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        color: #fff !important;
        border-color: #fff !important;
        margin-right: 24px;
        z-index: 1;
        &:focus {
          color: #fff !important;
          border-color: #fff !important;
        }
      }
    }
  }

  // }
}
.question-list-font-family {
  h3 {
    @extend .custom-fontFamily;
  }
}
#font-picker {
  box-shadow: none;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  .font-list {
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
  button {
    background-color: white;
    min-height: 40px;
    &:hover {
      background-color: #a9a7a7;
    }
  }
}
.carousal-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  .previous-btn {
    position: absolute;
    top: 45%;
    left: 5px;
    transform: translateY(-40%);
    background-color: rgba(0,0,0, 0.5);
    color: white;
    border: rgb(79, 76, 76);
    border-radius: 50%;
    padding: 0;
    width: 38px;
    height: 38px;
  }
  .forward-btn {
    position: absolute;
    top: 45%;
    right: 5px;
    transform: translateY(-40%);
    background-color: rgba(0,0,0, 0.5);
    color: white;
    border: rgb(79, 76, 76);
    border-radius: 50%;
    padding: 0;
    width: 38px;
    height: 38px;
  }
  .carousal-video-text {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    display: none;
    color: black;
    font-weight: 500;
    font-size: 18px;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -50px;
    cursor: pointer;
  }
  .ant-carousel .slick-dots li.slick-active {
    border-radius: 50%;
    background-color: #000;
  }
  .ant-carousel .slick-dots li:not(.slick-active) {
    border-radius: 50%;
    background-color: #968f8e;
  }
  .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 8px !important;
    height: 8px !important;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
    background: #968f8e;
  }
  .ant-carousel .slick-dots li button {
    width: auto;
    border-radius: 50%;
  }
  &:hover{
  .carousal-video-text {
    display: block;
    background-color: rgba(0,0,0, 0.5);
    h3{
      color: white;
    }
    p{
      color: white;
    }
  }
  .play-pause-button{
    display: block;
    background-color: transparent;
    color: white;
    z-index: 999;
  }
}
.play-pause-button{
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: #4f4c4c;
  border-radius: 50%;
  padding: 0;
  font-size: 75px;
  display: none;
}
}


.link-btn {
  padding-top: 5px;
  padding-bottom: 5px;
}
.video-share-modal-icons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.card-heading-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3{
    margin-bottom: 0 !important;
  }
  .ant-tag{
    margin-right: 0;
  }
}