.header-container {
  padding-left: 24px;
  padding-right: 24px;
  // -webkit-box-shadow: 1px 28px 45px -9px rgba(223, 223, 235, 0.6);
  // -moz-box-shadow: 1px 28px 45px -9px rgba(223, 223, 235, 0.6);
  // box-shadow: 1px 28px 45px -9px rgba(223, 223, 235, 0.6);
}
.header-2 {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  font-weight: bold;
}
.header-3 {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
