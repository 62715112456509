.content-area {
  .dashboard-header {
    max-width: 455px;

    h3 {
      @extend .font-weight-semibold;
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      color: #4e4e4e;
      margin-bottom: 3.25rem;
    }
  }

  .dashboard-card {
    transition: all .5s;
    box-shadow: 0px -5px 20px rgba(174, 170, 170, 0.25);

    &:hover {
      margin-top: -5px;
      box-shadow: 0px -5px 20px rgba(174, 170, 170, 0.6);
    }

    .ant-card-body {
      @extend .p-0,
      .cursor-pointer;
      border-radius: 4px;
      overflow: hidden;
    }

    .dc-icon {
      @extend
      /* .d-flex,
      .justify-content-center,
      .align-items-center, */
      .w-100,
      .position-relative;

      .anticon {
        @extend .position-absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        @extend .d-block;
        padding-top: 64%;
        content: '';
      }
    }

    h6 {
      @extend .mb-0,
      .font-weight-semibold,
      .d-flex,
      .align-items-center;
      font-size: 1rem;
      color: #212121;
      padding: 1.5rem;
      height: 75px;
    }

    & .rooms-bg {
      background: linear-gradient(111.44deg, #569bf2 5.79%, #87b8f6 95.58%);
    }

    & .video-archive-bg {
      background: linear-gradient(110.64deg, #9b68f6 7.86%, #b38bfa 96.64%);
    }

    & .settings-bg {
      background: linear-gradient(112.22deg, #e55da2 7.93%, #f26fbf 96.44%);
    }

    & .account-bg {
      background: linear-gradient(109.64deg, #f9c948 2.26%, #fce068 99.98%);
    }

    span.anticon {
      @extend .text-white;
      font-size: 3.75rem;
    }
  }
}